import React from "react"
// import styled from "styled-components"
import Page from "../../components/page"
import UnderConstruction from "../../components/underConstruction"

const PageVjCourse = () => {
  return (
    <Page title="VJコース">
      <UnderConstruction />
    </Page>
  )
}

export default PageVjCourse
