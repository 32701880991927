import React from "react"
import styled from "styled-components"
import bgHeader from "../images/bg-header.jpg"
import SEO from "./seo"

const Page = ({ title, children, showTitleBar = true }) => {
  return (
    <>
      <SEO title={title} />
      {showTitleBar && <PageHeader>
        <h1>{title}</h1>
      </PageHeader>}
      {children}
    </>
  )
}

export default Page

const PageHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  background: url(${bgHeader}) no-repeat center/cover;

  h1 {
    margin: 0;
    padding: 0.25em 2em;
    border: 2px solid white;
    border-radius: 2em;
    background: black;
    font-size: 2rem;

    @media (max-width: 420px) {
      font-size: 1.5rem;
    }
  }
`
