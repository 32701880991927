import React from "react"
import styled from "styled-components"

const CompUnderConstruction = () => {
  return <UnderConstruction>準備中</UnderConstruction>
}

export default CompUnderConstruction

const UnderConstruction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  font-size: 2rem;
`
